type Content = Element[] | Element

/**
 * Fetches an element with the specified dk-target value
 *
 * @param root
 * @param target
 * @returns
 */
export const forTarget = <T>(root: RootElement, target: string): Maybe<T> => {
  return root.querySelector(`[dk-target="${target}"]`) as Maybe<T>
}

/**
 * Added and Element or array of elements to a parent element
 *
 * @param root
 * @param target
 * @param content
 * @param direction
 */
function _addElementToParent (
  root: RootElement,
  target: string,
  content: Content,
  direction: 'append' | 'prepend',
): void {
  const container = forTarget<HTMLElement>(root, target)
  if (!container || !content) return

  Array.isArray(content)
    ? content.forEach(element => container[direction](element))
    : container[direction](content)
}

/**
 * Appends an element to a parent container element
 *
 * @param root
 * @param target
 * @param content
 * @returns
 */
export function appendTo (root: RootElement, target: string, content: Content): void {
  _addElementToParent(root, target, content, 'append')
}

/**
 * Prepends an element to a parent container element
 *
 * @param root
 * @param target
 * @param content
 * @returns
 */
export function prependTo (root: RootElement, target: string, content: Content): void {
  _addElementToParent(root, target, content, 'prepend')
}

/**
 * Removes all of the content of a parent div and replaces it with the specified content
 *
 * @param root
 * @param target
 * @param content
 */
export function replaceContents (root: RootElement, target: string, content: Content): void {
  const container = forTarget<HTMLElement>(root, target)
  if (!container || !content) return
  container.innerHTML = ''

  appendTo(root, target, content)
}
