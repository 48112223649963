const Cookie = require('js-cookie')

if (Cookie.get('CookieConsent')) {
  $('.cookies').hide()
}

$('.iagree').on('click', () => {
  Cookie.set('CookieConsent', true)
  $('.cookies').hide()
})
