(() => {
  const Cookie = require('js-cookie')

  if (Cookie.get('DisableAlert1')) {
    $('.alert__banner').hide()
  }

  $('.alert__closex').on('click', () => {
    Cookie.set('DisableAlert1', true)
    $('.alert__banner').hide()
  })
})()
