import { mount } from '@core/mount'

const Tableside = (root: HTMLElement) => {
  const forwardToThanx = () => {
    setTimeout(() => {
      window.location = ('https://order.thanx.com/hopdoddy' + window.location.search) as unknown as Location
    }, Number(process.env.INTERSTITIAL_TIMEOUT))
  }

  return {
    start: mount(root, forwardToThanx),
  }
}

export default Tableside
