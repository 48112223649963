// Dirty trick to stop videos from playing when in the background
const reloadIframes = () => {
  $("iframe").attr("src", $("iframe").attr("src"))
};

const preventBackgroundScroll = () => {
  $('body').css('overflow', 'hidden')
}

const enableBackgroundScroll = () => {
  $('body').css('overflow', 'unset')
}

export default {
  init() {
    $('.modaltrigger').on('click', preventBackgroundScroll)
    $('.modal__closebuttonwrap').on('click', reloadIframes)
    $('.modal__closebuttonwrap').on('click', enableBackgroundScroll)
  },
};
