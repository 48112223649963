const sageColor = '#35a54d'
const scaler = 1.5

const resetColors = () => {
  $('.stateicon svg').css({ transform: 'scale(1)', fillOpacity: 0 })
  $('.state-name__wrap').css({ opacity: 0 })
  $('[data-state-icon] path').css({ fillOpacity: 0 })
}

const highlightState = (element: HTMLElement) => {
  resetColors()

  $(element)
    .find('.state-name__wrap')
    .css({
      zIndex: 100,
      opacity: 100,
      transition: 'all 0.2s ease-in'
    })

  $(element)
    .find('svg')
    .css({
      fillOpacity: 1,
      transition: 'all 0.2s ease-in',
      transform: `scale(${scaler})`
    })

  $(element)
    .find('[data-state-icon] path')
    .css({ fillOpacity: 1 })
}

$('#state-icons a').on('click', function(e) {
  highlightState(this)
})

// Highlight the first in the list
highlightState($('#state-icons a')[0])

