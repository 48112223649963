import axios from 'axios'

interface IHours {
  day: string,
  opens: string,
  closes: string
}

const storeHours = $('.storehours')
const API_URL = process.env.API_URL

const getStoreHours = async (): Promise<IHours[]> => {
  const { locationOloId } = $('[data-location-olo-id]').data()
  const { data: { data: hours } } = await axios(`${API_URL}/calendars/${locationOloId}`)

  return hours
}

const formatStoreHours = (opens: string, closes: string) => {
  if (!opens || !closes) return 'Closed'
  return `${opens} - ${closes}`
}

const setStoreHours = async () => {
  const hours = await getStoreHours()
  const container = $('.lt__hours')

  // Remove the placeholders
  container.html('')

  // Fill in the store hours
  hours.forEach((hour) => {
    container.append(`
      <div class="lt__weekday">
        <div>${hour.day}</div>
        <div class="hours">
          ${formatStoreHours(hour.opens, hour.closes)}
        </div>
      </div>
    `)
  })
}

if (storeHours.length) setStoreHours()

export default null
