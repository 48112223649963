import { fromEvent, interval } from 'rxjs'
import { mount } from '@core/mount'
import { throttle } from 'rxjs/operators'

const TabsToAccordions = (root: HTMLElement) => {
  const handleConversion = () => {
    const tablist = document.querySelector('[dk-tablist]')
    const tabs = Array.from(document.querySelectorAll('[dk-tabpanel-id]'))
    const firstTab = document.querySelector('[dk-tabpanel-id]')
    const tabpanels = Array.from(document.querySelectorAll('[dk-tab-id]'))
    const firstTabpanel = document.querySelector('[dk-tab-id]')
    const accordions = document.querySelectorAll('[dk-accordion]')

    /**
     * isMobile
     *
     * checks if current screen width is equal to or smaller than 767px
     *
     * @returns boolean
     */
    const isMobile = () => {
      const mobileBreakpoint = '767'
      const mq = window.matchMedia(`(max-width: ${mobileBreakpoint}px)`)
      if (mq.matches) {
        return true
      } else {
        return false
      }
    }

    /**
     * handleResize
     *
     * if screen width is mobile, convert to accordions, if desktop, convert to tabs
     */
    const handleResize = () => {
      if (isMobile() === true) {
        // hide tablist
        tablist?.setAttribute('style', 'display: none;')

        // remove aria-selected and role from all tabs
        tabs.forEach((tab) => {
          tab.removeAttribute('aria-selected')
          tab.removeAttribute('role')
        })

        // show all tabpanels
        tabpanels.forEach((panel) => {
          panel.removeAttribute('hidden')
        })

        // collapse all accordions
        accordions.forEach((accordion) => {
          accordion.firstElementChild?.setAttribute('aria-expanded', 'false')
        })
      } else {
        // show tabslist
        tablist?.setAttribute('style', 'display: flex;')

        // deselect all tabs, reset their role
        tabs.forEach((tab) => {
          tab.setAttribute('aria-selected', 'false')
          tab.setAttribute('role', 'tab')
        })

        // hide all tabpanels
        tabpanels.forEach((panel) => {
          panel.setAttribute('hidden', 'hidden')
        })

        // select first tab
        firstTab?.setAttribute('aria-selected', 'true')

        // show the first tabpanel
        firstTabpanel?.removeAttribute('hidden')

        // expand all accordions
        accordions.forEach((accordion) => {
          accordion.firstElementChild?.setAttribute('aria-expanded', 'true')
        })
      }
    }

    // recheck screen size on resize events
    const resize = fromEvent(window, 'resize')
    const widths = resize.pipe(throttle(() => interval(300)))
    widths.subscribe(handleResize)

    // check screen size on page load
    handleResize()
  }

  return {
    start: mount(root, handleConversion),
  }
}

export default TabsToAccordions
