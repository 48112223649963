import axios from 'axios'
import { mount } from '@core/mount'

interface IAllergen {
  name: string
  slug: string
  diary: boolean
  eggs: boolean
  fish: boolean
  gluten: boolean
  peanuts: boolean
  shellfish: boolean
  soy: boolean
  treenuts: boolean
  wheat: boolean
}

const toggleDot = (element: JQuery<Element>, allergen: string, show = true) => {
  const dot = element.find(`[data-allergen="${allergen}"]`)

  if (show) {
    dot.html('<span style="opacity: 0; visibility: hidden;">true</span>')
    dot.attr('role', 'cell')
    dot.show()
  } else {
    dot.html('<span style="opacity: 0; visibility: hidden;">false</span>')
    dot.attr('role', 'cell')
    dot.hide()
  }
}

const setAllergens = async () => {
  const { data: menuItems } = await axios.get(process.env.API_URL + '/allergens')
  const template = $('[data-allergen-template]')[0]
  const ingredients = [
    'dairy',
    'diary',
    'eggs',
    'fish',
    'shellfish',
    'treenuts',
    'peanuts',
    'wheat',
    'soy',
    'gluten',
  ]

  menuItems.forEach((item: any) => {
    const { allergens } = item

    Object.keys(allergens).forEach((slug) => {
      const values: IAllergen = $(allergens[slug])[0]
      const row = $(template).clone()
      const target = $(`[data-menu-item="${item.slug}"]`)
        .parents('.accordion_content')
        .find('.allergens_wrap')
      const allergenHeadingsRow = $('.allergenstitle')
      const allergenHeadings = $('.allergentitle')

      // Set the role for the table
      target.attr('role', 'table')
      target.attr('aria-label', 'Allergens')

      // Set the role for column headings row
      allergenHeadingsRow.attr('role', 'row')

      // Set the role for each column heading (ex. 'dairy')
      allergenHeadings.each(function (i) {
        this.setAttribute('role', 'columnheader')
      })

      // Set the role for each row (ex. 'Egg Bun')
      row.attr('role', 'row')

      // Set the values for each item
      row.find('[data-allergen-title]').text(values.name)
      row.find('[data-allergen-title]').attr('role', 'rowheader')
      // @ts-expect-error
      ingredients.forEach((ingredient) => toggleDot(row, ingredient, values[ingredient]))

      // Display it on the page
      target.append(row)
      row.css({ display: 'grid', gridGap: 0 })
    })
  })
}

const Nutritionals = (root: HTMLElement) => {
  return {
    start: mount(root, setAllergens),
  }
}

export default Nutritionals
