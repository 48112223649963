import { on } from '@core/on'
import { mount } from '@core/mount'
import { ajax } from 'rxjs/ajax'
import { Subject } from 'rxjs'
import type { ComponentActions } from '@core/bootstrap'
import { forTarget } from '@core/content'
import axios from 'axios'

/* eslint no-var: "off" */
declare var grecaptcha: any

type FormStep = 'recaptcha' | 'complete' | 'error'

export default function (root: RootElement): ComponentActions {
  let verifiedHuman = false
  let form: HTMLFormElement
  const RECAPTCHA_PUBLIC_KEY = process.env.RECAPTCHA_PUBLIC_KEY!
  const step$ = new Subject<FormStep>()

  /**
   * https://developers.google.com/recaptcha/docs/v3
   *
   * reCAPTCHA v3 returns a score for each request without user friction. The score is based on interactions with your
   * site and enables you to take an appropriate action for your site. Register reCAPTCHA v3 keys
   * [here](https://g.co/recaptcha/v3).
   */
  function handleRecaptcha () {
    grecaptcha.ready(() => {
      grecaptcha.execute(RECAPTCHA_PUBLIC_KEY, { action: 'submit' })
        .then((token: string) => {
          ajax.post(process.env.API_URL + '/recaptcha', { token }).subscribe({
            complete () {
              step$.next('complete')
            },
            error () {
              step$.next('error')
            },
          })
        })
    })
  }

  async function handleComplete () {
    verifiedHuman = true
    const targetEmail = form.dataset.email

    if (targetEmail) {
      const email = `${targetEmail}@hopdoddy.com`
      const data = new FormData(form)
      // @ts-ignore
      const formData = Object.fromEntries(data.entries())

      // TODO: Call the api to send the form data to the given email address
      try {
        await axios.post(process.env.API_URL + '/forms', { email, formData })

        form.style.display = 'none'
        forTarget<HTMLDivElement>(root, 'thank-you')!.style.display = 'block'
      } catch (error) {
        console.error(error)

        form.style.display = 'none'
        forTarget<HTMLDivElement>(root, 'error')!.style.display = 'block'
      }

    } else {
      $(form).trigger('submit')
    }
  }

  function handleError () {
    console.error('There was an error submitting the form')
  }

  function handleSubmit (event: Event, _: unused, target: Element) {
    event.preventDefault()
    form = target as HTMLFormElement

    // Webflow attaches the submit event handler to the document. Since we're submitting a form, we can block the event
    // propagation and check the user for signs of circuitry. However, if we know the user is not a bot we can just
    // sendit like my hero, Larry Enticer.
    if (verifiedHuman) return
    event.stopPropagation()

    step$.next('recaptcha')
  }

  async function handleStepSubscription (step: FormStep) {
    switch (step) {
      case 'recaptcha':
        handleRecaptcha()
        break
      case 'complete':
        await handleComplete()
        break
      default:
        handleError()
    }
  }

  return {
    watch: [
      { target: step$, actions: [handleStepSubscription] },
    ],
    start: mount(root,
      on('contact-submission', handleSubmit),
    ),
  }
}
