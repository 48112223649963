import { ComponentActions } from '@core/bootstrap'
import { forTarget } from '@core/content'
import { mount } from '@core/mount'
import { BehaviorSubject } from 'rxjs'

export default function (root: RootElement): ComponentActions {
  const category$ = new BehaviorSubject<string | null>(null)
  const dynamic$ = new BehaviorSubject<boolean>(false)

  // Scroll to the top of the categories when the category changes unless the user is already at the top

  const scrollToTop = () =>
    new Promise(() => {
      const targetPosition = 600 // The position you want to scroll to
      const currentPosition = window.pageYOffset || document.documentElement.scrollTop

      if (currentPosition > targetPosition) {
        window.scrollTo({
          top: targetPosition,
          behavior: 'smooth',
        })
      }
    })

  // Hides the CTA banner and appends it to the <html> element
  function moveCTABanner () {
    const ctaBanner = document.querySelector<HTMLDivElement>('[dk-target="feeling-hungry"]')
    ctaBanner?.setAttribute('style', 'display: none;')
    document.getElementsByTagName('html')[0].appendChild(ctaBanner!)
  }

  // Sets up the CTA banner by applying styles and inserting it before a target element
  function setCTABanner () {
    const ctaBanner = document.querySelector<HTMLDivElement>('[dk-target="feeling-hungry"]')
    const target = forTarget<HTMLDivElement>(root, 'pagination-controls')
    const parent = target?.parentElement as HTMLDivElement

    ctaBanner?.setAttribute(
      'style',
      'padding-top:0; padding-left: 0; padding-bottom: 60px; margin-bottom: 0; display: block;',
    )

    if (target && parent) {
      parent.insertBefore(ctaBanner!, target)
    }
  }

  // Sets the category based on the search parameters and emits the updated category
  function setCategoryFromSearchParams () {
    const params = new URLSearchParams(window.location.search)
    const category = params.get('categories')
    if (!category) return

    category$.next(category)
  }

  async function handlePagination () {
    // Get the container element that holds the blog list items
    const container = forTarget<HTMLDivElement>(document, 'blog-list-items')!

    // Get all of the category links
    const categoryLinks = root.querySelectorAll<HTMLLinkElement>('[dk-target="category-link"]')

    const nextButton = forTarget<HTMLLinkElement>(container, 'next')
    const prevButton = forTarget<HTMLLinkElement>(container, 'prev')

    // Loop through the next and previous buttons
    for (const button of [...Array.from(categoryLinks), nextButton, prevButton]) {
      // If a button is not present, continue to the next iteration
      if (!button) continue

      // Attach an async click event handler to the button
      button.onclick = async (e: Event) => {
        e.preventDefault()

        // Get the target link element and make a fetch request to its href
        const target = e.currentTarget as HTMLLinkElement

        const resp = await fetch(target!.href!)
        const htmlText = await resp.text()

        // Parse the HTML response text into an HTML document
        const parser = new DOMParser()
        const htmlDocument = parser.parseFromString(htmlText, 'text/html')

        // Get the replacement container from the parsed HTML document
        const replacement = forTarget<HTMLDivElement>(htmlDocument, 'blog-list-items')!

        // Replace the content of the original container with the replacement container's content
        moveCTABanner()
        container.innerHTML = replacement.innerHTML
        setCTABanner()

        if (target.dataset.category) {
          dynamic$.next(true)
          const params = new URLSearchParams(window.location.search)
          const category = target.dataset.category.replace(' ', '+')
          params.set('categories', category) // Set the desired parameter

          const newUrl = `${window.location.pathname}?${params.toString()}`
          history.pushState({}, '', newUrl)
        }

        scrollToTop()

        // Call the 'foo' function again to reinitialize event handlers on the replaced content
        handlePagination()
      }
    }
  }

  function handleCategoryChange (category: string | null) {
    if (!category || dynamic$.getValue()) return

    const target = document.querySelector<HTMLLinkElement>(`[data-category="${category}"]`)
    target?.click()
  }

  return {
    watch: [
      {
        target: category$,
        actions: [handleCategoryChange],
      },
    ],
    start: mount(root, handlePagination, setCategoryFromSearchParams, setCTABanner),
  }
}
