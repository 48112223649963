import axios from 'axios'

const toggleStoreHours = (element: Element) => {
  const elem = element.closest('.maptile__locationswrap')
  const closed = elem?.querySelector<HTMLDivElement>('[dk-target="closed"]')!
  const storeHours = elem?.querySelector<HTMLDivElement>('[dk-target="store-hours"]')!

  closed.style.display = 'flex'
  storeHours.style.display = 'none'
}

const getTodaysHours = () => {
  $('[data-location-olo-id]').each((_, element) => {
    const { locationOloId: id } = $(element).data()
    const endpointURL = `https://na6c0i4fb0.execute-api.us-west-2.amazonaws.com/calendars/${id}/current`

    if (Number.isInteger(id)) {
      axios.get(endpointURL).then(({ data }) => {
        const { opens, closes } = data

        // Check if the hours are in the correct format, if not, hide the hours
        if (!opens.match(/\d{1,2}:\d{2}(am|pm)/g) || !closes.match(/\d{1,2}:\d{2}(am|pm)/g)) {
          return toggleStoreHours(element)
        }

        const parent = $(element).parents('.maptile__locationswrap')
        const states = $(`[data-states-location-olo-id="${id}"]`).parents('.alllocations__hourswrap')

        parent.find('[data-hours-open]').text(opens)
        parent.find('[data-hours-closed]').text(closes)
        states.find('[data-hours-open]').text(opens)
        states.find('[data-hours-closed]').text(closes)
      }).catch(() => toggleStoreHours(element))
    }
  })
}

export default getTodaysHours
