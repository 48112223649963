const red = '#E33600'
const white = '#FFF'
const scaler = 1.5

const resetColors = () => {
  $('.ingredient_tabtrigger path')
    .css({ fillOpacity: 1, stroke: white, fill: 'none' })
    .attr('path', white)

  $('.ingredient_tabtrigger polyline')
    .css({ fillOpacity: 1, stroke: white, fill: 'none' })
}

const highlightIngredient = (element: HTMLElement) => {
  resetColors()

  $(element)
    .find('.ingredient__icon')
    .css({
      zIndex: 100,
      opacity: 100,
      transition: 'all 0.2s ease-in'
    })

  $(element)
    .find('path')
    .css({ fillOpacity: 1, stroke: red, fill: red })
    .attr('stroke', red)
    .attr('fill', red)

  $(element)
    .find('polyline')
    .css('stroke', red)
}

export default {
  init () {
    // Set the z-index of the icon labels. This should probably be done in webflow
    $('.ingredientname').css('z-index', 500)

    $('.ingredient_tabtrigger').on('click', function(e) {
      highlightIngredient(this)
    })
  }
}
