import './app/modules/alertBanner'
import './app/modules/cookies'
import './app/modules/locationStoreHours'
import './app/modules/map'
import './app/modules/states'
import { bootstrap } from '@core/bootstrap'
import { Integrations } from '@sentry/tracing'
import { startDKApp } from '@core/app'
import * as Sentry from '@sentry/browser'
import Accordion from './app/modules/accordion'
import Anchors from './app/modules/anchors'
import ContactForm from './app/components/ContactForm'
import ConvertTags from './app/modules/convertTags'
import CurrentYear from './app/modules/currentYear'
import Dialog from './app/modules/dialog'
import getTodaysHours from './app/modules/todaysHours'
import Modals from './app/modules/modals'
import Nav from './app/modules/nav'
import Nutritionals from './app/components/Nutritionals'
import Quality from './app/modules/quality'
import Tableside from './app/components/Tableside'
import Tabs from './app/modules/tabs'
import TabsToAccordions from './app/components/TabsToAccordions'
import Videos from './app/modules/videos'
import Blog from './app/components/BlogPagination'

declare global {
  interface Window {
    google: Record<string, unknown>
  }
}

startDKApp(() => {
  // Run first
  ConvertTags()

  // Global modules
  CurrentYear()
  Dialog()
  Nav()
  Tabs()
  Accordion()
  Anchors()
  Videos()
  getTodaysHours()

  // Components
  bootstrap('nutritionals', [Nutritionals], {})
  bootstrap('tableside', [Tableside], {})
  bootstrap('contact-form', [ContactForm], {})
  bootstrap('tabs-to-accordions', [TabsToAccordions], {})
  bootstrap('blog', [Blog], {})
  Quality.init()
  Modals.init()
})

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: Number(process.env.SENTRY_SAMPLE_RATE),
})
