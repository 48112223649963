export default null
import axios from 'axios'

declare global {
  interface Window {
    HGC: Boolean,
    google: Record<string, unknown>,
    initMap: Function
  }
}

interface ICoords {
  latitude: number,
  longitude: number
}

const apiURL = process.env.API_URL
const mapContainer = $('.map__main__container')[0]
const locationCards = $('[data-location-card]')
let map: google.maps.Map

const toggleLocationBlocks = (ids: number[]) => {
  const container = $('[data-location-card-container]')

  // Clear out the parent container
  container.html('')

  // Add the location cards back based on the order they're returned from the Olo API
  ids.forEach((value: number) => {
    const location = locationCards.filter((_, location) => {
      const oloId = $(location).find('[data-location-olo-id]').data('location-olo-id')
      return oloId === value
    })[0]

    container.append(location)
  })
}

const getRestaurantsNearLocation = async (lat: number, long: number) => {
  let { data: { restaurants } } = await axios.get(`${apiURL}/restaurants/near`, {
    params: { lat, long }
  })

  const ids = restaurants.map((r: any) => r.id)
  toggleLocationBlocks(ids)
}

const generateMap = (coords?: ICoords) => {
  map = new google.maps.Map(mapContainer as HTMLElement, {
    center: {
      lat: coords?.latitude || 30.357,
      lng: coords?.longitude || -97.731 },
    zoom: 8,
  })

  const markers = $(document).find('[data-location-id]').each((_index, element) => {
    const lat = $(element).data('location-lat')
    const lng = $(element).data('location-long')
    const title = $(element).data('location-id')
    const oloID = $(element).data('location-olo-id')

    const marker = new google.maps.Marker({
      position: {lat, lng},
      animation: google.maps.Animation.DROP,
      map,
      title
    })

    google.maps.event.addDomListener(marker, 'click', () => {
      toggleLocationBlocks([oloID])
      showMapListWrapper()
    })
  })
}

const getCurrentLocation = (callback?: Function) => {
  navigator.geolocation.getCurrentPosition(({coords}) => {
    // If viewing the map on Desktop, we need to offset the map a bit to make
    // up for the floating search box.
    const offset = window.innerWidth > 700 ? 0.8 : 0
    const position = { lat: coords.latitude, lng: coords.longitude }

    map.setCenter({...position, lng: coords.longitude - offset})

    new google.maps.Marker({
      position,
      animation: google.maps.Animation.DROP,
      map
    })

    callback && callback(position)
  }, console.error, {})
}

const showMapListWrapper = () => {
  setTimeout(() => {
    $('.collection-list-wrapper-3').css({ display: 'block' })
  }, 300)
}

// Places Search
const handleLocationSearch = () => {
  $('form.search').on('submit', (e) => {
    e.preventDefault()
    const service = new google.maps.places.PlacesService(map)
    const value = {
      query: $('#search').val(),
      fields: ['name', 'geometry']
    } as any //<-- This hurts my delicate and beautiful soul

    service.findPlaceFromQuery(value, (results, status) => {
      if (status === 'OK') {
        const { lat, lng } = results?.[0].geometry!.location!

        getRestaurantsNearLocation(lat(), lng())
        map.setCenter({ lat: lat(), lng: lng() - 0.8 })
        showMapListWrapper()
      }
    })
  })
}

const handleLocationChange = (autocomplete: object) => {
  google.maps.event.addDomListener(autocomplete, 'place_changed', () => {
    $('form.search').trigger('submit')
  })
}

const initGooglePlacesSearch = () => {
  const options = {
    componentRestrictions: { country: 'us' }
  }

  const input = $('#search')[0] as HTMLInputElement
  const autocomplete = new google.maps.places.Autocomplete(input, options);

  handleLocationSearch()
  handleLocationChange(autocomplete)
}

const initMap = () => {
  getCurrentLocation()
  generateMap()

  $('[data-current-location]').on('click', () => {
    getCurrentLocation(({lat, lng}: {lat: number, lng: number}) => getRestaurantsNearLocation(lat, lng)!)
    showMapListWrapper()
  })
}

///

if (mapContainer) {
  google.maps.event.addDomListener(window, 'load', initMap)
  initGooglePlacesSearch()
}
